
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

// Services
import ScanService from "@/services/ScanService";
import LocationService from "@/services/LocationService";
import ActionService from "@/services/ShipService";
import PartsService from "@/services/PartsService";
import LotService from "@/services/LotInfoService";
import InventoryService from "@/services/inventory";
import Utils from "@/utility/utils";
const scanService = new ScanService(process.env.VUE_APP_ABSTRACTION_API);
const locationService = new LocationService(
  process.env.VUE_APP_ABSTRACTION_API
);
const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API
);
const lotService = new LotService(process.env.VUE_APP_ABSTRACTION_API);
const partsService = new PartsService();
const shipService = new ActionService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    Button,
    MessageBox,
  },
  data() {
    return {
      shipmentPayload: {
        to_loc: "",
        ship_id: "",
        from_loc: "",
        from_bin: "",
        lot: "",
        part: "",
        quantity: 0.0,
        update_ship: false,
        allow_new_quantity: false,
        ship_short: false,
        user_id: "",
      },
      ship_pick_li: {} as any,
      lot_control: false,
      from_inv_bin_control: false,
      from_inv_neg_ok: false,
      from_inv_phys: false,
      fractions: false,

      balance: 0.0,
      loadingSaveButton: false,
      loadingExitButton: false,
      showIsShipComplete: false,
      showOpenItemList: false,
      showConfirmPickDone: false,
      showConfirmQuantity: false,
      showErrorDialog: false,
      errorMessage: "",
      focusRefName: "",
      openItemListMessage: "",
      messageConfirmQuantity: "",
      update_ship: false,
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono p-input-icon-right",
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getUser: "session/getUser",
    }),
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    clearAll() {
      this.shipmentPayload.to_loc = "";
      this.shipmentPayload.ship_id = "";
      this.shipmentPayload.from_loc = "";
      this.shipmentPayload.from_bin = "";
      this.shipmentPayload.lot = "";
      this.shipmentPayload.part = "";
      this.shipmentPayload.ship_short = false;
      this.shipmentPayload.allow_new_quantity = false;
      this.shipmentPayload.quantity = 0;
      this.shipmentPayload.update_ship = false;
      this.shipmentPayload.user_id = this.getUser.user_id;

      this.ship_pick_li = {};
      this.lot_control = false;
      this.update_ship = false;
      this.from_inv_bin_control = false;
      this.from_inv_neg_ok = false;
      this.from_inv_phys = false;
      (this.fractions = false), (this.balance = 0.0);
    },
    clearSubmit() {
      this.shipmentPayload.lot = "";
      this.shipmentPayload.part = "";
      this.shipmentPayload.quantity = 0;
      this.shipmentPayload.update_ship = false;
      this.shipmentPayload.ship_short = false;
      this.shipmentPayload.allow_new_quantity = false;

      this.ship_pick_li = {};
      this.lot_control = false;
      this.update_ship = false;
      this.from_inv_bin_control = false;
      this.from_inv_neg_ok = false;
      this.from_inv_phys = false;
      (this.fractions = false), (this.balance = 0.0);
    },
    focusInput(refName: string) {
      if (refName) {
        (this.$refs[refName] as any).$el.focus();
        (this.$refs[refName] as any).$el.select();
      }
    },
    start() {
      var err = null;
      if (!this.shipmentPayload.to_loc) {
        err = "To location required.";
        this.focusInput("to_loc");
      }

      if (!this.shipmentPayload.ship_id) {
        err = "Ship ID required.";
        this.focusInput("ship_id");
      }

      if (!this.shipmentPayload.from_loc) {
        err = "From location required.";
        this.focusInput("from_loc");
      }

      if (err) {
        this.handleError(err);
        return;
      }

      this.loadingSaveButton = true;
      scanService
        .postShipment(this.shipmentPayload)
        .then((resp: any) => {
          this.handleSuccessDialog(resp?.message || "Successfully started shipment");
          this.clearSubmit();
          this.focusInput("part");
        })
        .finally(() => {
          this.loadingSaveButton = false;
        });
    },
    handleToLocation() {
      if (this.shipmentPayload.to_loc) {
        locationService.getLocationById(
          this.getClient,
          this.shipmentPayload.to_loc,
          "invloc_id bin_control type"
        )
          .then((resp: any) => {
            if (resp && resp[0]) {
              const location = resp[0];
              this.shipmentPayload.to_loc = location.invloc_id;

              if (location.bin_control === "Y") {
                throw new Error("Bin controlled location not allowed");
              }

              if (location.type === "WO") {
                throw new Error("WIP location not allowed");
              }

              if (this.shipmentPayload.ship_id) {
                shipService
                  .getShipById(
                    this.getClient,
                    this.shipmentPayload.ship_id,
                    "ship_id pull_loc"
                  )
                  .then((resp: any) => {
                    if (resp && resp[0]) {
                      if (resp.pull_loc != this.shipmentPayload.to_loc) {
                        this.handleMessage(
                          "Shipment pull to location has already been set to " +
                          resp.pull_loc + "."
                        );
                        this.shipmentPayload.to_loc = resp.pull_loc;
                      }
                    }
                  });
              }

              this.focusInput("ship_id");
            } else {
              throw new Error(
                this.shipmentPayload.to_loc +
                " is not a valid inventory location"
              );
            }
          })
          .catch((err) => {
            this.handleErrorAndFocus(
              err.toString() || "Error with To Location.",
              "to_loc"
            );
          });
      }
    },
    handleShipId() {
      if (this.shipmentPayload.ship_id) {
        const fieldNames =
          "ship_id status pull_date pull_loc pack_pull_date pick_li pick_part pick_ship_qty pick_location pick_qty_pulled pack_scan_qty";
        shipService
          .getShipById(this.getClient, this.shipmentPayload.ship_id, fieldNames)
          .then((resp: any) => {
            if (resp && resp.ship_items && resp.ship_items.length > 0) {
              const ship_item = resp.ship_items[0];
              this.shipmentPayload.ship_id = ship_item.ship_id;

              if (ship_item.status != "N" || ship_item.pull_date) {
                throw new Error("Shipment has already been pulled.");
              }

              if (ship_item.pull_loc != this.shipmentPayload.to_loc) {
                this.shipmentPayload.to_loc = ship_item.pull_loc
                throw new Error(`Shipment pull to location has already been set to ${ship_item.pull_loc}.`);
              }

              this.ship_pick_li = ship_item.pick_li_items;

              this.focusInput("from_loc");
            } else {
              this.handleErrorAndFocus(
                "No SHIP with ID " + this.shipmentPayload.ship_id + " found.",
                "ship_id"
              );
            }
          })
          .catch((err) => {
            this.handleErrorAndFocus(
              err.toString() || "Error with Ship ID.",
              "ship_id"
            );
          });
      }
    },
    handleFromLocation() {
      if (this.shipmentPayload.from_loc) {
        const [from_loc, bin] = Utils.splitStringInTwo(this.shipmentPayload.from_loc, "-");
        this.shipmentPayload.from_loc = from_loc;
        this.shipmentPayload.from_bin = bin;

        locationService
          .getLocationById(
            this.getClient,
            this.shipmentPayload.from_loc,
            "invloc_id bin_control type phys neg_ok"
          )
          .then((resp: any) => {
            if (resp.invloc_items && resp.invloc_items[0]) {
              const location = resp.invloc_items[0];
              this.shipmentPayload.from_loc = location.invloc_id;
              this.shipmentPayload.from_bin = bin;

              if (location.type === "WO") {
                throw new Error("WIP location not allowed");
              }

              this.from_inv_bin_control = location.bin_control === "Y";

              if (location.bin_control != "Y") {
                this.shipmentPayload.from_bin = "";
                this.focusInput("part");
                return;
              }

              if (this.from_inv_bin_control && bin) {
                this.focusInput("part");
                return;
              }

              this.from_inv_bin_control = resp.neg_ok === "Y";

              this.focusInput("from_bin");
            } else {
              throw new Error(from_loc + " is not a valid inventory location");
            }
          })
          .catch((err) => {
            this.shipmentPayload.from_loc = "";
            this.shipmentPayload.from_bin = "";
            this.handleErrorAndFocus(
              err.toString() || "Error with From Location.",
              "from_loc"
            );
          });
      }
    },
    handleFromBin() {
      if (!this.from_inv_bin_control) {
        const message =
          this.shipmentPayload.from_loc + " is not a bin controlled location.";
        this.handleError(message);
        this.shipmentPayload.from_bin = "";
      } else if (!this.shipmentPayload.from_bin) {
        const message = this.shipmentPayload.from_loc + "Bin number required.";
        this.handleError(message);
        this.shipmentPayload.from_bin = "";
      } else {
        this.focusInput("part");
      }
    },
    handlePart() {
      if (this.shipmentPayload.part) {
        partsService
          .getPartByID({
            client: this.getClient,
            id: this.shipmentPayload.part,
            fieldnames: "part_no lot_control fractions",
          })
          .then((resp: any) => {
            this.shipmentPayload.part = resp.part_no;
            this.lot_control = resp.lot_control === "Y";
            this.fractions = resp.fractions === "Y";
            if (!this.lot_control) {
              this.focusInput("quantity");
            } else {
              this.focusInput("lot");
            }
          })
          .catch((err) => {
            const message =
              err.response.data.message ||
              this.shipmentPayload.part + " is not a valid part number.";
            this.handleErrorAndFocus(message, "part");
          });
      }
    },
    handleLot() {
      if (this.lot_control) {
        if (this.shipmentPayload.lot) {
          lotService
            .getLot(this.shipmentPayload.lot, "lot_number part_number")
            .then((resp: any) => {
              this.shipmentPayload.lot = resp.lot_number;
              let found = false;
              if (this.shipmentPayload.part != resp.part_number) {
                throw new Error(
                  "Lot number " +
                  this.shipmentPayload.part +
                  " is for part " +
                  resp.part_number
                );
              }

              const pick_li = this.ship_pick_li.find(
                (pick_li: any) =>
                  pick_li.pick_part === this.shipmentPayload.part
              );

              if (!pick_li) {
                throw new Error(
                  "Part number and location not found on shipment."
                );
              }

              if (pick_li.pick_location === this.shipmentPayload.from_loc) {
                const req_qty = parseFloat(pick_li.pick_ship_qty) || 0.0;
                let scan_qty = 0;
                pick_li.pick_qty_pulled_items.forEach((item: any) => {
                  scan_qty += parseFloat(item.pick_qty_pulled);
                });

                this.balance = req_qty - scan_qty;
              }

              if (!this.balance) {
                throw new Error("No balance left to scan for this part.");
              }
            })
            .catch((err) => {
              const message =
                err || this.shipmentPayload.lot + " is not a valid Lot.";
              this.handleErrorAndFocus(message, "lot");
            });
        } else {
          this.handleErrorAndFocus("Lot number required.", "lot");
        }
      } else {
        const message = this.shipmentPayload.part + " is not lot controlled";
        this.handleErrorAndFocus(message, "lot");
      }
    },
    handleQuantity() {
      if (!this.fractions) {
        if (!Number.isInteger(+this.shipmentPayload.quantity)) {
          const message = "Fractions are not permitted";
          this.handleError(message);
          return;
        }
      }

      if (this.shipmentPayload.quantity > 0) {
        if (this.shipmentPayload.quantity > this.balance) {
          this.handleError("Quantity exceeds balance to pull for this part.");
          return;
        }

        if (this.from_inv_bin_control) {
          if (!this.from_inv_neg_ok) {
            inventoryService
              .getInventoryById(
                this.getClient,
                this.shipmentPayload.part,
                "part_no invloc bin bin_qty on_hand",
                ""
              )
              .then((resp: any) => {
                const invloc = resp.inv_items[0].invloc_items.find(
                  (item: any) => item.invloc === this.shipmentPayload.from_loc
                );

                if (invloc) {
                  const bin = invloc.bin_items.find(
                    (item: any) => item.bin === this.shipmentPayload.from_bin
                  );
                  if (bin) {
                    if (
                      this.shipmentPayload.quantity > parseFloat(bin.bin_qty)
                    ) {
                      this.handleError("Insufficient quantity in bin.");
                    }
                  } else {
                    this.handleError(
                      "Bin not found for location " +
                      this.shipmentPayload.from_loc
                    );
                  }

                  let on_hand = 0;
                  if (invloc.on_hand) {
                    on_hand = parseFloat(invloc.on_hand) || 0.0;
                  }

                  if (this.from_inv_phys) {
                    const new_quantity =
                      on_hand - this.shipmentPayload.quantity;
                    this.messageConfirmQuantity = `Balance will become ${new_quantity}. Okay?`;
                    this.showConfirmQuantity = true;
                  }
                } else {
                  this.handleError("Inventory location not found.");
                }
              });
          }
        }
      }
    },
    clickedOpenItemList(response: boolean) {
      if (response) {
        this.focusInput("part");
      } else {
        this.showConfirmPickDone = true;
      }
      this.showOpenItemList = false;
    },
    clickedConfirmPickDone(response: boolean) {
      if (response) {
        this.shipmentPayload.ship_short = true;
        this.update_ship = true;
      }
      this.showConfirmPickDone = false;
    },
    clickedConfirmQuantity(response: boolean) {
      if (response) {
        this.shipmentPayload.allow_new_quantity = true;
      }
      this.showConfirmQuantity = false;
    },
    clickedIsShipComplete(response: boolean) {
      if (response) {
        const fieldNames =
          "ship_id status pull_date pull_loc pack_pull_date pick_li pick_part pick_ship_qty pick_location pick_qty_pulled pack_scan_qty";
        shipService
          .getShipById(this.getClient, this.shipmentPayload.ship_id, fieldNames)
          .then((resp: any) => {
            if (resp && resp.ship_items && resp.ship_items.length > 0) {
              const ship_item: any = resp.ship_items[0];
              this.shipmentPayload.ship_id = ship_item.ship_id;
              const open_li_list: any[] = [];

              ship_item.pick_li_items.forEach((pick_li: any) => {
                const req_qty = parseFloat(pick_li.pick_ship_qty) || 0.0;
                let scan_qty = 0;
                pick_li.pick_qty_pulled_items.forEach((item: any) => {
                  scan_qty += parseFloat(item.pick_qty_pulled);
                });

                if (req_qty > scan_qty) {
                  open_li_list.push(
                    pick_li.pick_li + " - " + pick_li.pick_part
                  );
                }
              });

              if (open_li_list.length > 0) {
                this.showOpenItemList = true;
                this.openItemListMessage =
                  "The following line items have not been scanned:\r\n";
                this.openItemListMessage += open_li_list.join("\r\n");
                this.openItemListMessage +=
                  "\r\n\r\nDo you want to continue scanning?";
              } else {
                this.update_ship = true;
              }
            }
          });
      }
      this.showIsShipComplete = false;
    },
    handleExitTime() {
      this.showIsShipComplete = true;
    },
    handleError(message: string) {
      const notification = {
        dialog: true,
        type: "error",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    handleMessage(message: string) {
      const notification = {
        dialog: true,
        type: "success",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      if (this.focusRefName) {
        this.focusInput(this.focusRefName);
      }
      this.focusRefName = "";
    },
    handleSuccessDialog(message: string) {
      const notification = {
        dialog: true,
        type: "success",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    updateShip() {
      this.shipmentPayload.update_ship = true;
      this.loadingExitButton = true;
      scanService
        .postShipment(this.shipmentPayload)
        .then((resp: any) => {
          this.handleSuccessDialog(
            resp?.message || "Successfully updated shipment."
          );
        })
        .finally(() => {
          this.loadingExitButton = false;
          this.shipmentPayload.update_ship = false;
        });
    },
  },
  mounted() {
    this.focusInput("to_loc");
    this.clearAll();
  },
  watch: {
    update_ship(ship) {
      if (ship) {
        this.updateShip();
        this.update_ship = false;
      }
    },
  },
});
